import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectActionPermission } from 'store/selectors/account';
import {
  createSupplier,
  fetchSuppliersForFilter,
  fetchSupplierTypes,
  modifySupplier,
} from 'api/suppliers';
import { ExternalPaymentMethods, SupplierHandlingTypes, SupplierReturnFeeTypes } from 'consts';
import * as Yup from 'yup';
import toaster from 'services/toaster';
import { extractApiErrors } from 'utils/api';
import { useFormik } from 'formik';
import { useTranslator } from 'i18n';
import { fetchRetailers } from 'api/retailers';
import { fetchWarehouses } from 'api/warehouses';
import { fetchMondoEmails } from 'api/mondoEmails';
import { fetchAllCompanies } from 'api/companies';
import numeral from 'numeral';

const { DEFAULT, DROPSHIPPING_AUTO_ORDER, TRANSIT } = SupplierHandlingTypes;

const getReturnFeeType = (isFixedFee, isVariableFee) => {
  const fixedFee = isFixedFee && SupplierReturnFeeTypes.FIXED;
  const variableFee = isVariableFee && SupplierReturnFeeTypes.PERCENT;

  return (fixedFee && variableFee) ? SupplierReturnFeeTypes.BOTH :
    (fixedFee || variableFee) ? fixedFee || variableFee : null;
};

export const DesktopView = ({
  routes,
  supplier,
  callFetchingSupplier,
  formState,
  setFormState,
  onSupplierUpdate,
  activeMarketplaces,
}) => {
  const t = useTranslator();
  const history = useHistory();
  const [suppliers, setSuppliers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [paymentsMethods, setPaymentsMethods] = useState((supplier?.paymentsMethods && [...supplier.paymentsMethods]) || []);
  const [mondoEmails, setMondoEmails] = useState([]);
  const [warehouses, setWarehouses] = useState((supplier?.transitWarehouse && [supplier?.transitWarehouse]) || []);
  const [handlingTypes, setHandlingTypes] = useState({ ...SupplierHandlingTypes });
  const [retailers, setRetailers] = useState((supplier?.retailer && [supplier?.retailer]) || []);

  const canEditToken = useSelector(selectActionPermission('suppliers', 'token'));
  const canEditFTP = useSelector(selectActionPermission('suppliers', 'ftp'));
  const canChangeCreateInvoices = useSelector(selectActionPermission('suppliers', 'createInvoices'));

  const [initialValues, setInitialValues] = useState({
    address: {
      name: '',
      additionalName: '',
      country: '',
      street: '',
      city: '',
      zip: '',
    },
    partnerBillingType: false,
    isPartnerBillingTypeFee: true,
    isSelfOwned: false,
    generateBarcode: false,
    showLogoOnPickupSheets: true,
    handlingType: DEFAULT,
    paymentsMethods,
    retailerIds: [],
    warehouseId: '',
    number: '',
    datevNumber: '',
    externalSupplier: '',
    active: true,
    token: '',
    name: '',
    note: '',
    productExport: false,
    createInvoices: true,
    sendTracking: true,
    ftp: {
      remotePath: '',
      username: '',
      password: '',
      port: '',
      host: '',
    },
    affiliateId: '',
    isAutoCalculateSalesPrices: false,
    isUndercutAutomatically: true,
    isCalculatePricesOnStockExport: false,
    pricesCalculations: activeMarketplaces?.map(marketplace => ({
      marketplaceCountryId: marketplace.id,
      countryCode: marketplace.country.countryCode,
      salesPriceMarketPriceRate: 100,
      salesPriceGeneralPriceRate: 200,
      salesPriceFixedRate: 10,
      costpriceProductPriceType: 'netPrice',
      billFeesToPartner: false,
      invoicingFee: t.formatDecimalToPercentage(0),
      marketplaceFee: t.formatDecimalToPercentage(0),
      premiumFee: t.formatDecimalToPercentage(0),
      priceCalculationFee: t.formatDecimalToPercentage(0),
      baseShippingCosts: t.formatPrice(0),
      offSeason: t.formatPrice(0),
      summerSeason: t.formatPrice(0),
      winterSeason: t.formatPrice(0),
    })) || [],
    mondoEmail: {
      id: '',
      name: '',
    },
    companies: [],
    mondoEmails: [],
    isFixedFees: false,
    isVariableFees: false,
    isAnonymousSelling: false,
    isBillUsedStorage: true,
    resellingAccountId: supplier ? '' : 0,
    isUseRepatriationAddress: false,
    repatriationAddress: {
      additionalName: '',
      country: '',
      street: '',
      house: '',
      name: '',
      city: '',
      zip: '',
    },
    invoiceAddress: {
      additionalName: '',
      country: '',
      street: '',
      house: '',
      name: '',
      city: '',
      zip: '',
    },
    invoiceInfo: {
      vatId: '',
      vatNumber: '',
      debitorNumber: '',
      creditorNumber: '',
      bankName: '',
      accountHolder: '',
      iban: '',
      swift: '',
      email: '',
      language: 'de',
      sepaMandate: '',
      sepaMandateDate: '',
    },
    contactInfos: activeMarketplaces?.map(marketplace => ({
      marketplaceCountryId: marketplace.id,
      countryCode: marketplace.country.countryCode,
      email: '',
      invoiceEmail: '',
      contactPerson: '',
      phone: '',
      fax: '',
      ustId: '',
      taxId: '',
      taxArea: '',
      bankName: '',
      bankHolder: '',
      bankIBAN: '',
      bankBIC: '',
    })) ?? [],
    summerStartDate: '03-01'.split('-'),
    summerEndDate: '05-31'.split('-'),
    winterStartDate: '10-01'.split('-'),
    winterEndDate: '12-31'.split('-'),
    isHandlingCosts: false,
    isShippingCosts: false,
    isDifferingShippingAddressCosts: false,
    isRegionalSurchargeCosts: false,
    paymentMethod: ExternalPaymentMethods[0],
    isReplenishmentSuggestionsEnabled: false,
    replenishmentSuggestionsEmail: '',
  });

  const validationSchema = Yup
    .object()
    .shape({
      number: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a supplier number.')),
      name: Yup
        .string()
        .trim()
        .required(t.translate('Please specify a supplier name.')),
      affiliateId: Yup
        .string()
        .when(['partnerBillingType'], {
          is: (partnerBillingType) => partnerBillingType === true,
          then: Yup.string().trim().required(t.translate('Please specify an affiliate account number.')),
        }),
      externalSupplier: Yup
        .string()
        .when(['handlingType'], {
          is: (handlingType) => ([DROPSHIPPING_AUTO_ORDER, TRANSIT].includes(handlingType)),
          then: Yup.string().trim().required(t.translate('Please specify an external supplier number.')),
        }),
      retailerIds: Yup
        .array()
        .when(['handlingType'], {
          is: (handlingType) => ([DROPSHIPPING_AUTO_ORDER, TRANSIT].includes(handlingType)),
          then: Yup.array().of(Yup.number().positive()).min(1),
        }),
      warehouseId: Yup
        .number()
        .when(['handlingType'], {
          is: (handlingType) => handlingType === TRANSIT,
          then: Yup.number().positive().required(t.translate('Please specify transit warehouse.')),
        }),
      isAutoCalculateSalesPrices: Yup.boolean(),
      isUndercutAutomatically: Yup.boolean(),
      isAnonymousSelling: Yup.boolean(),
      pricesCalculations: Yup.array().when('isAutoCalculateSalesPrices', {
        is: true,
        then: Yup.array().of(
          Yup.object().shape({
            marketplaceCountryId: Yup.number().positive().typeError(t.translate('Must be positive number')),
            salesPriceMarketPriceRate: Yup.number().min(0).transform((_value, originalValue) => numeral(originalValue).value()).label(t.translate('Market price amount')),
            salesPriceGeneralPriceRate: Yup.number().min(0).transform((_value, originalValue) => numeral(originalValue).value()).label(t.translate('General price amount')),
            salesPriceFixedRate: Yup.number().min(0).typeError(t.translate('Must be positive number')).label(t.translate('Fixed Amount')),
            billFeesToPartner: Yup.boolean(),
            invoicingFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            marketplaceFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            premiumFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            priceCalculationFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            baseShippingCosts: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            offSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            summerSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            winterSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          })
        ),
        otherwise: Yup.array().of(
          Yup.object().shape({
            billFeesToPartner: Yup.boolean(),
            invoicingFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            marketplaceFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            premiumFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            priceCalculationFee: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            baseShippingCosts: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            offSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            summerSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
            winterSeason: Yup.number().transform((_value, originalValue) => numeral(originalValue).value()),
          })
        ),
      }),
      isFixedFees: Yup.boolean(),
      isVariableFees: Yup.boolean(),
      isBillUsedStorage: Yup
        .boolean()
        .when(['handlingType'], {
          is: (handlingType) => handlingType === DEFAULT,
          then: Yup.boolean().required(t.translate('Bill used storage is required')),
        }),
      resellingAccountId: Yup
        .number()
        .required(t.translate('Please specify account for reselling.')),
      isUseRepatriationAddress: Yup.boolean(),
      repatriationAddress: Yup.object().when('isUseRepatriationAddress', {
        is: true,
        then: Yup.object().shape({
          country: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a country.')),
          city: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a city.')),
          street: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a street.')),
          zip: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a zip code.')),
          house: Yup
            .string()
            .trim()
            .required(t.translate('Please specify a house.')),
        }),
      }),
      invoiceAddress: Yup.object().shape({
        country: Yup
          .string()
          .trim()
          /* todo: utilize default.js config from backend */
          .oneOf([
            'Deutschland', 'Germany',
            'Frankreich', 'France',
            'Österreich', 'Austria',
            'Belgien', 'Belgium', 'België', 'Belgique',
            'Spanien', 'Spain', 'España', 'Espanya', 'Espainia',
            'Niederlande', 'Netherlands', 'Nederland', 'Nederlân',
            'Luxemburg', 'Luxembourg', 'Lëtezebuerg',
            'Polen', 'Poland', 'Polska',
            'Italien', 'Italy', 'Italia',
            'Estland', 'Estonia', 'Eesti',
          ])
          .required(t.translate('Please specify a country.')),
        city: Yup
          .string()
          .trim()
          .required(t.translate('Please specify a city.')),
        street: Yup
          .string()
          .trim()
          .required(t.translate('Please specify a street.')),
        zip: Yup
          .string()
          .trim()
          .required(t.translate('Please specify a zip code.')),
        house: Yup
          .string()
          .trim()
          .nullable(true),
      }),
      contactInfos: Yup.array().of(
        Yup.object().shape({
          email: Yup.string().email().trim().nullable(true),
        })
      ),
      invoiceInfo: Yup.object().shape({
        vatId: Yup.string().nullable().trim().nullable(true),
        vatNumber: Yup.string().trim().nullable(true),
        debitorNumber: Yup.string().trim().nullable(true),
        creditorNumber: Yup.string().trim().nullable(true),
        bankName: Yup.string().trim().nullable(true),
        accountHolder: Yup.string().trim().nullable(true),
        iban: Yup.string().trim().nullable(true),
        swift: Yup.string().trim().nullable(true),
        email: Yup.string().email().nullable(true),
        language: Yup.string().trim().nullable(true),
        sepaMandate: Yup.string().trim().nullable(true),
        sepaMandateDate: Yup.string().trim().nullable(true),
      }),
      paymentMethod: Yup.string().oneOf(ExternalPaymentMethods),
      replenishmentSuggestionsEmail: Yup.string().email().trim().nullable(true),
    });

  const onSubmit = async (values, { setErrors }) => {
    try {
      const payload = (({ ftp, isFixedFees, isVariableFees, ...values }) => ({
        ...values,
        mondoEmailId: mondoEmails.find(value => value.name === values.mondoEmail.name)?.id,
        ...(Object.keys(ftp).some(key => ftp[key].trim().length) && { ftp }),
        returnFeeType: getReturnFeeType(isFixedFees, isVariableFees),
        isBillUsedStorage: values.handlingType === DEFAULT ? values.isBillUsedStorage : false,
        resellingAccountId: values.resellingAccountId,
        repatriationAddress: values.isUseRepatriationAddress ? values.repatriationAddress : null,
        summerStartDate: values.summerStartDate.join('-'),
        summerEndDate: values.summerEndDate.join('-'),
        winterStartDate: values.winterStartDate.join('-'),
        winterEndDate: values.winterEndDate.join('-'),
        pricesCalculations: values.pricesCalculations.map(value => ({
          ...value,
          invoicingFee: t.parsePercentage(value.invoicingFee, 4),
          marketplaceFee: t.parsePercentage(value.marketplaceFee, 4),
          premiumFee: t.parsePercentage(value.premiumFee, 4),
          priceCalculationFee: t.parsePercentage(value.priceCalculationFee, 4),
          baseShippingCosts: numeral(value.baseShippingCosts).value(),
          offSeason: numeral(value.offSeason).value(),
          summerSeason: numeral(value.summerSeason).value(),
          winterSeason: numeral(value.winterSeason).value(),
          marketplaceCountryId: Number(value.marketplaceCountryId),
          salesPriceMarketPriceRate: t.parsePercentage(value.salesPriceMarketPriceRate),
          salesPriceGeneralPriceRate: t.parsePercentage(value.salesPriceGeneralPriceRate),
          salesPriceFixedRate: Number(value.salesPriceFixedRate),
        })),
      }))(values);

      if (supplier) {
        const { isFailedToExport } = await modifySupplier(supplier.id, payload);

        if (isFailedToExport) {
          onExportError();
        }
        else {
          onSupplierSave();
          setFormState(state => ({ ...state, isEditMode: false, errors }));
          onSupplierUpdate();
        }
      }
      else {
        await createSupplier(payload);
        toaster.success(t.translate('New supplier has been created successfully.'));
        onCloseSupplierCreatePage();
      }
    }
    catch (err) {
      setErrors(extractApiErrors(err));
    }
  };

  const onCloseSupplierCreatePage = () => history.push(`/suppliers`);

  const onSupplierSave = () => {
    toaster.success(t.translate('Supplier has been saved successfully.'));
  };

  const onExportError = () => {
    toaster.warning({
      content: t.translate('Failed to export products to FTP server.'),
      onClose: onSupplierSave,
    });
  };

  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
    errors,
  } = useFormik({
    enableReinitialize: true,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  useEffect(() => {
    if (supplier) {
      const pricesCalculations = supplier.pricesCalculations
        .filter(item => item.marketplaceCountry.isActive)
        .map((item) => ({
          ...item,
          salesPriceGeneralPriceRate: t.formatDecimalToPercentage(Number(item.salesPriceGeneralPriceRate)),
          salesPriceMarketPriceRate: t.formatDecimalToPercentage(Number(item.salesPriceMarketPriceRate)),
          countryCode: item.marketplaceCountry.country.countryCode,
          marketplaceCountryId: item.marketplaceCountryId,
          costpriceProductPriceType: item.costpriceProductPriceType,
          billFeesToPartner: item.billFeesToPartner,
          invoicingFee: t.formatDecimalToPercentage(Number(item.invoicingFee), 4),
          marketplaceFee: t.formatDecimalToPercentage(Number(item.marketplaceFee), 4),
          premiumFee: t.formatDecimalToPercentage(Number(item.premiumFee), 4),
          priceCalculationFee: t.formatDecimalToPercentage(Number(item.priceCalculationFee), 4),
          baseShippingCosts: t.formatPrice(Number(item.baseShippingCosts)),
          offSeason: t.formatPrice(Number(item.offSeason)),
          summerSeason: t.formatPrice(Number(item.summerSeason)),
          winterSeason: t.formatPrice(Number(item.winterSeason)),
        }));

      setInitialValues(initialValues => ({
        ...initialValues,
        address: {
          name: supplier.address.name,
          additionalName: supplier.address.additionalName,
          country: supplier.address.country,
          street: supplier.address.street,
          city: supplier.address.city,
          zip: supplier.address.zip,
        },
        partnerBillingType: supplier.partnerBillingType,
        isPartnerBillingTypeFee: supplier.isPartnerBillingTypeFee,
        isSelfOwned: supplier.isSelfOwned,
        generateBarcode: supplier.generateBarcode,
        showLogoOnPickupSheets: supplier.showLogoOnPickupSheets,
        handlingType: supplier.handlingType,
        paymentsMethods: supplier.paymentsMethods,
        retailerIds: supplier.retailers?.map(retailer => retailer.id) ?? [],
        warehouseId: supplier.transitWarehouse?.id || '',
        number: supplier.number,
        datevNumber: supplier.datevNumber || '',
        externalSupplier: supplier.externalSupplier || '',
        active: supplier.active,
        token: supplier.token || '',
        name: supplier.name,
        note: supplier.note || '',
        productExport: Boolean(supplier.productExport),
        billFeesToPartner: supplier.billFeesToPartner,
        createInvoices: supplier.createInvoices,
        sendTracking: supplier.sendTracking,
        costpriceProductPriceType: supplier.costpriceProductPriceType || initialValues.costpriceProductPriceType,
        ftp: {
          remotePath: supplier.ftp?.remotePath || '',
          username: supplier.ftp?.username || '',
          password: supplier.ftp?.password || '',
          port: supplier.ftp?.port || '',
          host: supplier.ftp?.host || '',
        },
        affiliateId: supplier.affiliateId || '',
        isAutoCalculateSalesPrices: supplier.isAutoCalculateSalesPrices || false,
        isUndercutAutomatically: supplier.isUndercutAutomatically ?? true,
        isCalculatePricesOnStockExport: supplier.isCalculatePricesOnStockExport || false,
        isAnonymousSelling: supplier.isAnonymousSelling,
        resellingAccountId: supplier.resellingAccountId,
        isBillUsedStorage: supplier.isBillUsedStorage,
        pricesCalculations,
        mondoEmail: supplier.mondoEmail,
        companies: supplier.companies,
        isFixedFees: (supplier.returnFeeType === SupplierReturnFeeTypes.BOTH || supplier.returnFeeType === SupplierReturnFeeTypes.FIXED),
        isVariableFees: (supplier.returnFeeType === SupplierReturnFeeTypes.BOTH || supplier.returnFeeType === SupplierReturnFeeTypes.PERCENT),
        isUseRepatriationAddress: !!supplier.repatriationAddress,
        ...(supplier.repatriationAddress && { repatriationAddress: supplier.repatriationAddress }),
        contactInfos: supplier.contactInfos
          .filter(item => item.marketplaceCountry.isActive)
          .map((item) => ({
            ...item,
            countryCode: item.marketplaceCountry.country.countryCode,
          })),
        ...(supplier.invoiceInfo?.invoiceAddress && { invoiceAddress: supplier.invoiceInfo.invoiceAddress }),
        ...(supplier.invoiceInfo && { invoiceInfo: supplier.invoiceInfo }),
        summerStartDate: supplier.summerStartDate?.split('-') || ['03', '01'],
        summerEndDate: supplier.summerEndDate?.split('-') || ['05', '31'],
        winterStartDate: supplier.winterStartDate?.split('-') || ['10', '01'],
        winterEndDate: supplier.winterEndDate?.split('-') || ['12', '31'],
        isHandlingCosts: supplier.isHandlingCosts,
        isShippingCosts: supplier.isShippingCosts,
        isDifferingShippingAddressCosts: supplier.isDifferingShippingAddressCosts,
        isRegionalSurchargeCosts: supplier.isRegionalSurchargeCosts,
        paymentMethod: supplier.paymentMethod,
        isReplenishmentSuggestionsEnabled: supplier.isReplenishmentSuggestionsEnabled,
        replenishmentSuggestionsEmail: supplier.replenishmentSuggestionsEmail,
      }));
    }
    /*eslint-disable-next-line*/
  }, [supplier]);

  useEffect(() => {
    const fetchInitialData = async () => {
      const [
        { handlingTypes },
        { data: retailers },
        { data: warehouses },
        mondoEmails,
        { data: suppliers },
        { data: companies },
      ] = await Promise.all([
        fetchSupplierTypes(),
        fetchRetailers({ limit: 50 }),
        fetchWarehouses({ meta: ['list-set'], limit: 50 }),
        fetchMondoEmails(),
        fetchSuppliersForFilter(),
        fetchAllCompanies(),
      ]);

      handlingTypes && setHandlingTypes(handlingTypes);
      retailers && setRetailers(retailers);
      warehouses && setWarehouses(warehouses);
      mondoEmails && setMondoEmails(mondoEmails);
      suppliers && setSuppliers(suppliers);
      companies && setCompanies(companies);
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (warehouses.length && !values.warehouseId) {
      setFieldValue('warehouseId', 1);
    }
  }, [setFieldValue, values.warehouseId, warehouses.length]);

  useEffect(() => {
    setFormState(state => ({ ...state, onSave: handleSubmit }));
  }, [setFormState, handleSubmit]);

  useEffect(() => {
    setFormState(state => ({ ...state, errors }));
  }, [setFormState, errors]);

  return (
    <Box display="flex" flexGrow={1} width={1}>
      <Switch>
        {routes.map(({ homePath, Page }) => (
          <Route key={homePath} exact path={homePath}>
            <Page
              isEditMode={formState?.isEditMode}
              onSave={callFetchingSupplier}
              canEditToken={canEditToken}
              canEditFTP={canEditFTP}
              canChangeCreateInvoices={canChangeCreateInvoices}
              suppliers={suppliers}
              activeMarketplaces={activeMarketplaces}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              handleChange={handleChange}
              handlingTypes={handlingTypes}
              retailers={retailers}
              paymentsMethods={paymentsMethods}
              setPaymentsMethods={setPaymentsMethods}
              mondoEmails={mondoEmails}
              supplier={supplier}
              companies={companies}
            />
          </Route>
        ))}
      </Switch>
    </Box>
  );
};
